/**
 * Type: ページ
 * What: ゴールデンウィーク2021はBOSS E・ZO FUKUOKA（ボス イーゾ フクオカ）で楽しもう！
 */
import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';

// import Parallax from 'react-rellax';
import { scroller } from 'react-scroll';
import Layout from '../../../components/Layout';
import SEO from '../../../components/Seo';
import BreadcrumbComponent from '../../../components/Breadcrumb';

import useMediaQuery from '../../../util/useMediaQuery';
import Image from '../../../util/Image';
import SimpleSwiper from '../../../components/Slider.lp';
import SlickSlider from './Slick';
// import { Button } from '../../components/Btn';
// import NavObm from '../../components/NavObm';
import '../../../sass/components/_webfont-puikko.scss'; // .puikko
import '../../../sass/components/_webfont-mplus1p.scss';

const OGP = '/ogp/event-gw-ogp.png'; // ogp画像

// import {Button} from '../../../components/Btn';
// import Arrow from '../../../images/btn/btn-def-arrow.svg';
// import userAgent from '../../../util/userAgent';

const mq = useMediaQuery;

const pageTitle =
  'ゴールデンウィーク2021はBOSS E・ZO FUKUOKA（ボス イーゾ フクオカ）で楽しもう！';
const pageDescription =
  'BOSS E・ZO FUKUOKAのゴールデンウィークは１日じゃ足りないっ！？福岡の気持ちのよい絶景や非日常空間など楽しい刺激がたくさん！動いたあとはおいしいグルメでおなかも満たされる♪イーゾで家族やともだちと楽しもう！';
const pageSlug = 'event-gw'; // OGP画像

// export const query = graphql`
//   {
//     site {
//       siteMetadata {
//         uuidObm
//         ticket
//         ticketReady
//       }
//     }
//   }
// `;

const SpotImage = (porps) => {
  const { slide } = porps;
  const pcImage = slide.map((obj) => (
    <li key={obj.filename}>
      <div className={obj.copy && 'mb10'}>
        <Image filename={obj.filename} width="1210" height="672" />
      </div>
    </li>
  ));

  return mq('pc') ? <ul>{pcImage}</ul> : <SimpleSwiper data={slide} />;
};
const scrollFunc = (porps, Offset) => {
  scroller.scrollTo(porps, {
    duration: 800,
    delay: 0,
    offset: Offset,
    smooth: 'easeOutQuint',
  });
};

// Data Props Template
const Template = ({ data }) => {
  const [Offset, setOffset] = useState(false);
  const scrollOffset = mq('pc') ? -100 : -60;

  useEffect(() => {
    setOffset(scrollOffset);
  }, [scrollOffset]);

  return (
    <Layout pageSlug={pageSlug} bg="bg-4">
      <SEO title={pageTitle} description={pageDescription} ogImage={OGP} />
      <BreadcrumbComponent
        hierarchy={2}
        bread2url="obm/"
        bread2title={pageTitle}
      />

      <section className="pageMain bg-12 bs-4">
        <div className="kv-wrap">
          <div className="kv-inner">
            <div className="kv-logo">
              <div data-sal="slide-up" data-sal-easing="ease-out-expo">
                <Image
                  src="gw-logo.svg"
                  width="74"
                  height="38"
                  alt="家族やお友達と福岡市で超たのしいエンタメ三味！ゴールデンウィーク2021"
                />
              </div>
            </div>
            <div className="top">
              <SlickSlider direction="left" num={5} />
            </div>
            <div className="bottom">
              <SlickSlider direction="right" num={5} />
            </div>
          </div>
        </div>
        <div className="parallax-wrap">
          <div className="description">
            <div
              data-sal="slide-up"
              data-sal-easing="ease-out-expo"
              className="inner"
            >
              <p>
                BOSS E・ZO FUKUOKAのゴールデンウィークは１日じゃ足りないっ！？
                <br />
                気持ちのよい絶景や非日常空間など楽しい刺激がたくさん！
                {mq('pc') && <br />}
                動いたあとはおいしいグルメでおなかも満たされる♪
                <br />
                イーゾで家族やともだちと楽しもう☆
              </p>
            </div>
          </div>
          {/*          {mq("pc") && <Parallax speed={-1} percentage={0.5} className="img">
            <Image filename="gw-img-parallax.jpg"/>
          </Parallax>
          } */}
        </div>

        <div className="about_measures">
          <Link
            to="/news/info/20230311252/"
            className=""
            data-sal="slide-up"
            data-sal-easing="ease-out-expo"
          >
            <Image filename="gw-mask.png" />
            <span>新型コロナウイルス感染防止の対策について</span>
            <Image filename="gw-thermometer.png" />
          </Link>
        </div>

        <div className="container is-fluid">
          <div className="sc-01">
            <div className="inner tac">
              <h3
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="outline mb30"
              >
                ゴールデンウィークは
                <br />
                <span className="gw-emphasis">
                  どーんと！
                  <br />
                  <b>営業時間拡大！</b>
                </span>
              </h3>

              <p
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="tac text is-1 is-1-sp ls-5 lh-5 mplus1p fwb mb10"
              >
                4月29日（木・祝）～5月9日（日）は
                <span className="marker">9:00オープン☆</span>
                <br />
                朝くると9歳以下のお子さまが入場無料のアトラクションも♪
              </p>
              <p
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="tac underline fwb"
              >
                <Link to="/news/event/202104022341/">詳しくはこちら</Link>
              </p>
              <div className="btn-group mt-5 mplus1p">
                <a
                  href="https://www.softbankhawks.co.jp/ex/gw/"
                  className="btn-def caution-ticket-btn narrow"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <span>
                    BOSS E・ZO FUKUOKA横
                    <br />
                    みずほPayPayドームで野球も楽しもう！
                    <br className="sp" />
                    イベントも開催
                  </span>
                  <Image src="btn-gw-arrow.svg" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div
          data-sal="slide-up"
          data-sal-easing="ease-out-expo"
          className="linkInPage"
        >
          <ul>
            <li>
              <button
                type="button"
                className="puikko"
                onClick={() => scrollFunc('event', Offset)}
              >
                {mq('pc') && (
                  <>
                    いまだけ！見逃せない
                    <br />
                  </>
                )}
                期間限定
                <br className="sp" />
                イベント
              </button>
            </li>
            <li>
              <button
                type="button"
                className="puikko"
                onClick={() => scrollFunc('zekkei', Offset)}
              >
                {mq('pc') && (
                  <>
                    絶景で絶叫！SNS映えも抜群
                    <br />
                  </>
                )}
                アトラク
                <br className="sp" />
                ション集
              </button>
            </li>
            <li>
              <button
                type="button"
                className="puikko"
                onClick={() => scrollFunc('gourmet', Offset)}
              >
                {mq('pc') && (
                  <>
                    おとなも子どもも大満足♪
                    <br />
                  </>
                )}
                絶品
                <br className="sp" />
                グルメ集
              </button>
            </li>
          </ul>
        </div>

        {/* うんこミュージアム */}
        <div className="container is-fluid container-arrow bg-1" id="event">
          <div className="event" id="event">
            <h2
              data-sal="slide-up"
              data-sal-easing="ease-out-expo"
              className="title is-2 c-0"
            >
              いまだけ！見逃せない
              <br className="sp" />
              期間限定イベント
            </h2>

            <div className="inner">
              <div className="spot">
                <div
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  className="spot-image"
                >
                  <Image filename="spot1-image.jpg" width="930" />
                </div>
                <div
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  className="spot-slide"
                >
                  <SpotImage
                    slide={[
                      {
                        filename: 'spot1-slide01.jpg',
                      },
                      {
                        filename: 'spot1-slide02.jpg',
                      },
                    ]}
                  />
                </div>
                <div className="spot-col">
                  <div
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="spot-logo"
                  >
                    <Link to="/unkomuseum/">
                      <Image
                        filename="spot1-logo.png"
                        width={mq('pc') ? 600 : 300}
                        height={mq('pc') ? 146 : 90}
                        alt="うんこミュージアム"
                      />
                    </Link>
                  </div>
                  <p
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="tac text is-1 is-3-sp ls-5 lh-9 tal"
                  >
                    インスタ映えならぬ「ウンスタ映え」なスポットや何度でも遊びたくなる中毒性の高いクソゲーセンターも！ぜひ童心に返って全力でお楽しみください。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* グッズ・グルメ情報 */}
        <div className="container is-fluid bg-21">
          <div className="goods-info">
            <h3
              data-sal="slide-up"
              data-sal-easing="ease-out-expo"
              className="subtitle is-4 fwb  tac mb-6"
            >
              グッズ・グルメ情報
            </h3>

            <div className="inner">
              <div className="sc-columns">
                <div
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  className="col col-4"
                >
                  <Link to="/mlbcafe-fukuoka/" className="col-inner">
                    <div className="col-image">
                      <figure className="image">
                        <Image
                          filename="spot1-goods01.jpg"
                          width="284"
                          height="200"
                        />
                      </figure>
                    </div>
                    <div className="col-content bg-12">
                      <h3 className="ttl c-4">
                        うんこミュージアムカレー
                        <br className="sp" />
                        (ミニサラダ付き)
                        <br className="sp" />
                        1,300円（税込）
                      </h3>
                      <p>
                        うんこミュージアム公式コラボメニュー！ビーツの自然なピンクで仕上げたマイルドなヨーグルトチキンカレー。
                      </p>
                    </div>
                  </Link>
                </div>
                <div
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  className="col col-4"
                >
                  <Link to="/news/space/202104192438/" className="col-inner">
                    <div className="col-image">
                      <figure className="image">
                        <Image
                          filename="spot1-goods02.jpg"
                          width="284"
                          height="200"
                        />
                      </figure>
                    </div>
                    <div className="col-content bg-12">
                      <h3 className="ttl c-4">
                        福岡限定ホークスコラボグッズも！
                      </h3>
                      {/* <p>うんこミュージアム公式コラボメニュー！ビーツの自然なピンクで仕上げたマイルドなヨーグルトチキンカレー。</p> */}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 5G LAB FUKUOKA */}
        <div className="container is-fluid">
          <div className="event">
            <div className="inner">
              <div className="spot">
                <div
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  className="spot-image"
                >
                  <Image filename="spot2-image.jpg" width="1440" height="650" />
                </div>
                <div className="spot-col">
                  <div
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="spot-logo"
                  >
                    <a href="/?anker-5glab">
                      <Image
                        filename="spot2-logo.png"
                        width={mq('pc') ? 300 : 100}
                        height={mq('pc') ? 411 : 137}
                        alt="5G LAB FUKUOKA"
                      />
                    </a>
                  </div>
                  <p
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="tac text is-1 is-3-sp ls-5 lh-9 tal"
                  >
                    5G環境での最先端の映像コンテンツや、アイドルグループ「NiziU（ニジュー）」とコラボレーションしたオリジナルコンテンツ、ホークス柳田選手とのバッティングフォーム比較「スイングものまねAI診断」が体験できるスペースが登場！
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* パララックス */}
        <div className="parallax-wrap">
          {/*          {mq("pc") &&
          <Parallax speed={-1} percentage={0.5} className="img">
            <Image src="gw-img-parallax.jpg"/>
          </Parallax>
          } */}
        </div>

        {/* 絶景3兄弟 SMBC日興証券 */}
        <div className="container is-fluid container-arrow bg-2" id="zekkei">
          <div className="event" id="event">
            <h2
              data-sal="slide-up"
              data-sal-easing="ease-out-expo"
              className="title is-2 c-0"
            >
              絶景で絶叫！SNS映えも抜群
              <br />
              アトラクション集
            </h2>

            <div className="inner">
              <div className="spot">
                <div
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  className="spot-image"
                >
                  <Image filename="spot3-image.jpg" width="940" height="424" />
                </div>
                <div
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  className="spot-slide"
                >
                  <SpotImage
                    slide={[
                      {
                        filename: 'spot3-slide01.jpg',
                      },
                      {
                        filename: 'spot3-slide02.jpg',
                      },
                    ]}
                  />
                </div>
                <div className="spot-col">
                  <div
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="spot-logo"
                  >
                    <Link to="/zekkei-brothers/">
                      <Image
                        filename="spot3-logo.png"
                        width={mq('pc') ? 600 : 300}
                        alt="絶景3兄弟 SMBC日興証券"
                      />
                    </Link>
                  </div>
                  <p
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="tac text is-1 is-3-sp ls-5 lh-9 tal"
                  >
                    眺めも気分も爽快な3種類の絶景アトラクション。全長100mのチューブ型スライダー「すべZO」、一人乗りのぶら下がり式レールコースター「つりZO」、クライミング&ボルダリング「のぼZO」!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* チームラボフォレスト */}
        <div className="container is-fluid container-arrow bg-17">
          <div className="event" id="event">
            <div className="inner">
              <div className="spot">
                <div
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  className="spot-image"
                >
                  <Image
                    filename="spot4-image.jpg"
                    width="940"
                    height="424"
                    class=""
                  />
                </div>
                <div
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  className="spot-slide"
                >
                  <SpotImage
                    slide={[
                      {
                        filename: 'spot4-slide01.jpg',
                        copy:
                          '©「チームラボフォレスト 福岡 - SBI証券」E・ZO FUKUOKA',
                      },
                      {
                        filename: 'spot4-slide02.jpg',
                        copy:
                          '©「チームラボフォレスト 福岡 - SBI証券」E・ZO FUKUOKA',
                      },
                    ]}
                  />
                  <p className="text is-0 tar c-0 pc">
                    ©「チームラボフォレスト 福岡 - SBI証券」E・ZO FUKUOKA
                  </p>
                </div>
                <div className="spot-col">
                  <div
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="spot-logo"
                  >
                    <Link to="/teamlabforest/">
                      <Image
                        filename="spot4-logo.png"
                        width={mq('pc') ? 600 : 300}
                        alt="チームラボフォレスト 福岡 – SBI証券"
                      />
                    </Link>
                  </div>
                  <p
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="tac text is-1 is-3-sp ls-5 lh-9 tal c-0"
                  >
                    「捕まえ、観察し、解き放つ」をコンセプトにした「捕まえて集める森」と「身体で世界を捉え、立体的に考える」をコンセプトにした「運動の森」からなる新しいミュージアム。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* VRエリア */}
        <div className="container is-fluid container-arrow bg-17">
          <div className="event" id="event">
            <div className="inner">
              <div className="spot">
                <div
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  className="spot-image"
                >
                  <Image filename="spot5-image.jpg" width="940" height="424" />
                </div>
                <div
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  className="spot-slide"
                >
                  <SpotImage
                    slide={[
                      {
                        filename: 'spot5-slide01.jpg',
                      },
                      {
                        filename: 'spot5-slide02.jpg',
                      },
                    ]}
                  />
                </div>
                <div className="spot-col">
                  <div
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="spot-logo"
                  >
                    <Link to="/v-world/">
                      <Image
                        filename="spot5-logo.png"
                        width={mq('pc') ? 600 : 300}
                        alt="V-World AREA クレディ・アグリコル"
                      />
                    </Link>
                  </div>
                  <p
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="tac text is-1 is-3-sp ls-5 lh-9 tal c-0"
                  >
                    コンテンツは18種37セット!360度の大迫力映像を体感できるドライビングシミュレーションVRや体験型音楽アトラクション、対戦型VRシューティングゲームなど複数のバーチャルコンテンツが楽しめる。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 王貞治ベースボールミュージアム */}
        <div className="container is-fluid container-arrow">
          <div className="event" id="event">
            <div className="inner">
              <div className="spot">
                <div
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  className="spot-image"
                >
                  <Image filename="spot6-image.jpg" width="940" height="424" />
                </div>
                <div
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  className="spot-slide"
                >
                  <SpotImage
                    slide={[
                      {
                        filename: 'spot6-slide01.jpg',
                      },
                      {
                        filename: 'spot6-slide02.jpg',
                      },
                    ]}
                  />
                </div>
                <div className="spot-col">
                  <div
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="spot-logo"
                  >
                    <Link to="/obm/">
                      <Image
                        filename="spot6-logo.png"
                        width={mq('pc') ? 600 : 300}
                        alt="王貞治ベースボールミュージアム・89パーク・スタジオ"
                      />
                    </Link>
                  </div>
                  <p
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="tac text is-1 is-3-sp ls-5 lh-9 tal mb10"
                  >
                    「野球人・王貞治」の歴史と魅力をたっぷり伝えるミュージアム。ホークス選手と競走できる「ホークスDASH」をはじめ、守備やバッティングなどにチャレンジできるアトラクションも充実。
                    <br />
                  </p>
                  <p className="underline-link">
                    <Link to="/news/obm/202103312320/">
                      元プロ野球選手がパーク内を巡回中♪
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* グッズ情報 */}
        <div className="container is-fluid bg-21">
          <div className="goods-info">
            <h3 className="subtitle is-4 fwb  tac mb-6">グッズ情報</h3>

            <div className="inner">
              <div className="sc-columns">
                <div
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  className="col col-4"
                >
                  <div className="col-inner">
                    <div className="col-image">
                      <figure className="image">
                        <Image
                          filename="spot6-goods01.jpg"
                          width="284"
                          height="200"
                        />
                      </figure>
                    </div>
                    <div className="col-content bg-12">
                      <h3 className="ttl c-4">
                        王貞治ベースボールミュージアム
                        <br />
                        オリジナル王貞治グッズ発売中！
                      </h3>
                      <p>
                        4F 王貞治ベースボールミュージアム
                        <br />
                        帽子やタオルなどから、おうちに飾りやすい扇子やサインボールも。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* パララックス */}
        <div className="parallax-wrap">
          {/*          {mq("pc") &&
          <Parallax speed={-1} percentage={0.5} className="img">
            <Image src="gw-img-parallax.jpg"/>
          </Parallax>
          } */}
        </div>

        {/* 絶品グルメ集 */}
        <div className="bg-22">
          <div
            className="container is-fluid container-arrow bg-none bg-3"
            id="gourmet"
          >
            <div className="event" id="event">
              <h2
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="title is-2 c-0"
              >
                おとなも子どもも大満足♪
                <br />
                絶品グルメ集
              </h2>

              <div className="inner">
                <div className="spot">
                  <div
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="spot-image"
                  >
                    <Image filename="spot7-image.jpg" width="930" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* グッズ・グルメ情報 */}
          <div className="container is-fluid bnone bg-none">
            <div className="goods-info">
              <h3 className="subtitle is-4 fwb  tac mb-6">グルメ集</h3>
              <div className="inner">
                <div className="sc-columns mb70">
                  <div
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="col col-4"
                  >
                    <Link to="/the-foodhall/" className="col-inner">
                      <div className="col-image">
                        <figure className="image">
                          <Image
                            filename="gw-gourmet01.jpg"
                            width="284"
                            height="200"
                          />
                        </figure>
                      </div>
                      <div className="col-content bg-12">
                        <h3 className="ttl c-4">
                          肉山のハンバーグ定食
                          <br />
                          1,300円（税込）
                        </h3>
                        <p>
                          3F The FOODHALL 肉山食堂
                          <br />
                          ボリューム満点の肉山オリジナルハンバーグをがっつり満喫！
                        </p>
                      </div>
                    </Link>
                  </div>

                  <div
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="col col-4"
                  >
                    <Link to="/the-foodhall/" className="col-inner">
                      <div className="col-image">
                        <figure className="image">
                          <Image
                            filename="gw-gourmet02.jpg"
                            width="284"
                            height="200"
                          />
                        </figure>
                      </div>
                      <div className="col-content bg-12">
                        <h3 className="ttl c-4">
                          花いかだ天籠
                          <br />
                          2,090円（税込）
                        </h3>
                        <p>
                          3F The FOODHALL 京都 天ぷら 花いかだ
                          <br />
                          ”ボリューム満点”３０ｃｍ級のアナゴを丸ごと1匹使用!!
                        </p>
                      </div>
                    </Link>
                  </div>

                  <div
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="col col-4"
                  >
                    <Link to="/mlbcafe-fukuoka/" className="col-inner">
                      <div className="col-image">
                        <figure className="image">
                          <Image
                            filename="gw-gourmet03.jpg"
                            width="284"
                            height="200"
                          />
                        </figure>
                      </div>
                      <div className="col-content bg-12">
                        <h3 className="ttl c-4">
                          ケイジャンチキンサンドウィッチ
                          <br />
                          1,400円（税込）
                        </h3>
                        <p>
                          3F MLB Café FUKUOKA
                          <br />
                          アメリカ南部のソウルフード「ケイジャン料理」皮はカリッと内側は刺激的でコクのあるケイジャンスパイスで仕上げたジューシーなスパイシーチキンを挟んだハンバーガー。
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>

                <h3 className="subtitle is-3 fwb  tac mb-6 puikko c-12">
                  <Image
                    src="c-12.svg"
                    width="73"
                    height="57"
                    alt="家族やお友達と福岡市で超たのしいエンタメ三味！ゴールデンウィーク2021"
                  />
                  お子様セットもたっぷりご用意
                </h3>
                <div className="sc-columns">
                  <div
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="col col-4"
                  >
                    <div className="col-inner">
                      <div className="col-image">
                        <figure className="image">
                          <Image
                            filename="gw-gourmet04.jpg"
                            width="284"
                            height="200"
                          />
                        </figure>
                      </div>
                      <div className="col-content bg-12">
                        <h3 className="ttl c-4">
                          肉山 お子様ハンバーグプレート
                          <br />
                          500円（税込）
                        </h3>
                        <p>
                          3F The FOODHALL 肉山食堂
                          <br />
                          みんな大好きハンバーグとステーキ、ウインナーのほか、デザートにゼリーもついたTheFOODHALL人気No.1のお子さまメニュー。
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="col col-4"
                  >
                    <div className="col-inner">
                      <div className="col-image">
                        <figure className="image">
                          <Image
                            filename="gw-gourmet05.jpg"
                            width="284"
                            height="200"
                          />
                        </figure>
                      </div>
                      <div className="col-content bg-12">
                        <h3 className="ttl c-4">
                          花いかだ お子様ランチ
                          <br />
                          500円（税込）
                        </h3>
                        <p>
                          3F The FOODHALL 京都 天ぷら 花いかだ
                          <br />
                          うどん、天むす、天ぷら3種、出汁巻きと、ドリンクがついた天ぷら店のお子様ランチ。
                          <br />
                          天ぷらはお子様に人気の「鶏肉、いも、かぼちゃ」の3種。
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="col col-4"
                  >
                    <div className="col-inner">
                      <div className="col-image">
                        <figure className="image">
                          <Image
                            filename="gw-gourmet06.jpg"
                            width="284"
                            height="200"
                          />
                        </figure>
                      </div>
                      <div className="col-content bg-12">
                        <h3 className="ttl c-4">
                          MLBキッズプレート(ジュース・おもちゃ付)
                          <br />
                          800円（税込）
                        </h3>
                        <p>
                          3F MLB Café FUKUOKA
                          <br />
                          MLB
                          caféこだわりのハンバーグをキッズサイズで！お子様用のジュースと選べるおもちゃがセットで付いております。
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="parallax-wrap2">
          <div className="description2">
            <div
              data-sal="slide-up"
              data-sal-easing="ease-out-expo"
              className="inner mb-6"
            >
              <p>
                各アトラクションを好きな時間に楽しむなら
                <br />
                事前購入がおすすめ！
              </p>

              <div className="btn-wrap">
                <a
                  href="https://ticket.e-zofukuoka.com/#/"
                  className="btn-regular"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>チケット購入</span>
                  <Image src="btn-def-arrow.svg" />
                </a>
              </div>

              <p className="">
                <Link to="/ticket/how" className="underline">
                  チケット購入方法はこちら
                </Link>
              </p>
            </div>

            <div
              data-sal="slide-up"
              data-sal-easing="ease-out-expo"
              className="inner"
            >
              <div className="btn-wrap mb-6 hover">
                <Link to="/news/info/202103031986/">
                  <Image filename="bnr-parking.jpg" />
                </Link>
              </div>

              <div className="btn-wrap mb-3">
                <Link to="/access/" className="btn-regular">
                  <span>アクセス</span>
                  <Image src="btn-def-arrow.svg" />
                </Link>
              </div>
              <div className="btn-wrap mb-6">
                <Link to="/schedule/" className="btn-regular">
                  <span>営業時間</span>
                  <Image src="btn-def-arrow.svg" />
                </Link>
              </div>

              <p>
                GW期間、福岡市内で家族や友達と遊ぶならBOSS E・ZO FUKUOKA！
                <br />
                みずほPayPayドームすぐ横のエンタメ施設で
                <br className="sp" />
                一日中遊ぼう♪
              </p>
            </div>
          </div>
        </div>

        {/*
      <NavObm />
      */}
      </section>
    </Layout>
  );
};

export default Template;
